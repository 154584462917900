import React from 'react'
import NavBar from './components/NavBar'
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import PlaybackPage from './screens/PlaybackPage'
import Entitlements from './screens/Entitlements'
import ProtectedRoute from './customroute/ProtectedRoute'
import LoginPageRoute from './customroute/LoginPageRoute'
import LoginPage from './screens/LoginPage'
import Footer from './components/Footer'
import DynPage from "./screens/DynPage";

function App() {
    return (
        <div className="App" style={myStyle.container}>
            <NavBar/>
            <div id="app-container">
                <BrowserRouter>
                    <Switch>
                        <LoginPageRoute exact path='/login' component={LoginPage}/>
                        <ProtectedRoute path='/playback' component={PlaybackPage}/>
                        <ProtectedRoute path='/' component={Entitlements}/>
                        {/*<Route path='/dynpath' component={DynPage}/>*/}
                    </Switch>
                </BrowserRouter>
            </div>
            <Footer/>
        </div>
    );
}

const myStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    }
}

export default App;
