import { getLoggedOutAction, getLoggedInAction } from '../actions/SessionActions'
import {saveDeviceUuid, saveSessionId, saveUsername} from '../utils/Prefs'

export const handleLogout = (dispatch) => {
    saveDeviceUuid(null)
    saveSessionId(null)
    saveUsername(null)
    dispatch(getLoggedOutAction())
}

export const attemptLogin = (username, password, uuid) => dispatch => {
    /*loginUser(username, password, uuid)
        .then(data => 
            saveSessionId(sessionId)
            dispatch(getLoggedInAction(sessionId))
        ).catch*/

}