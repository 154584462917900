export const ENTITLEMENT_CHOSEN = 'ENTITLEMENT_CHOSEN'
export const ENTITLEMENT_REMOVED = 'ENTITLEMENT_REMOVED'

export function addEntitlementChosen(entitlement) {
    return {
        type: ENTITLEMENT_CHOSEN,
        entitlement,
    }
}

export function getLoggedOutAction() {
    return {
        type: ENTITLEMENT_REMOVED,
    }
}