import React from 'react';
import ReactDependentScript from 'react-dependent-script'

export default class VideoPlayer extends React.Component {

    render() {
        return (<ReactDependentScript
            loadingComponent={<div>Player is loading</div>}
            scripts={['https://cdnjs.cloudflare.com/ajax/libs/video.js/8.11.1/video.min.js']}
            stylesheets={['https://cdnjs.cloudflare.com/ajax/libs/video.js/8.11.1/video-js.min.css']}
        >
            <div className="video-player"><VideoJsPlayer {...this.props} /></div>
        </ReactDependentScript>)
    }
}

class VideoJsPlayer extends React.Component {
    componentDidMount() {
        // instantiate Video.js
        const {metadataCallback, playbackUrl, contentType } = this.props
        this.player = window.videojs('my-player');
        this.player.fluid(true)
        this.player.responsive(true)
        this.player.ready(function () {
            this.src({
                src: playbackUrl,
                type: 'application/x-mpegURL'
            })
            this.on('loadedmetadata',function() {
                if(metadataCallback != null) {
                    metadataCallback()
                }
            })
        });
    }

    // destroy player on unmount
    componentWillUnmount() {

        if (this.player) {
            this.player.dispose()
        }
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        const { contentType } = this.props
        return (
            <div>
                <div data-vjs-player>
                    {
                        contentType === "audio" ?
                            <audio id="my-player" {...this.props} className="video-js vjs-default-skin vjs-big-play-centered" /> :
                            <video id="my-player" {...this.props} className="video-js vjs-default-skin vjs-big-play-centered" />

                    }

                </div>
            </div>
        )
    }
}
