import { LOGGED_IN, LOGGED_OUT } from '../actions/SessionActions'
import { ENTITLEMENT_CHOSEN, ENTITLEMENT_REMOVED } from '../actions/EntitlementActions'
import { combineReducers } from 'redux'
import { fetchSessionId, getSavedDeviceUuid } from '../utils/Prefs'

/*const initialState = {
    sessionId: 'sadad',
    //sessionId: fetchSessionId(),
    uuid: getSavedDeviceUuid()
  }*/

function session(state = [], action) {
    const { type, sessionId, userId, uuid } = action
    switch (type) {
        case LOGGED_IN:
            return {sessionId, userId, uuid}
        case LOGGED_OUT:
            return {}
        default:
            return state
    }
}

function entitlements(state = [], action) {
    const { entitlement, type } = action
    switch (type) {
        case ENTITLEMENT_CHOSEN:
            return { ...state, entitlement }
        case ENTITLEMENT_REMOVED:
            return { ...state, entitlement: null }
        default:
            return state
    }
}

export default combineReducers({
    session,
    entitlements
})