import React from 'react'
import Grid from '@material-ui/core/Grid'
import EntitlementCard from '../components/EntitlementCard'
import AlertDialog from '../components/AlertDialog'
import {
    checkEntitlementStatus,
    checkIfTransmissionStarted,
    fetchEntitlements,
    recordUserAttendance
} from '../networking/Api'
import {generateEventId} from '../utils/Utils'
import {addEntitlementChosen} from '../actions/EntitlementActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import {connect} from 'react-redux'
import ZoomCard from '../components/ZoomCard'
import Paper from "@material-ui/core/Paper";

class Entitlements extends React.Component {

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
        this.handleZoomClick = this.handleZoomClick.bind(this)
        this.handleOkClicked = this.handleOkClicked.bind(this)
        this.state = {isLoading: true}
    }

    componentDidMount() {
        fetchEntitlements(this.props.dispatch)
            .then(response => {
                var entitlements = response['entitlements']
                this.setState(prevState => ({
                    ...prevState,
                    entitlements,
                    isLoading: false
                }))
            })
            .catch(err => {
            })
        checkIfTransmissionStarted(this.props.dispatch)
            .then(response => {
                var status = response['status']
                if(status && status.length > 0 && status[0].META_VALUE && status[0].META_VALUE.trim().length > 0) {
                    return true
                }
                return false
            })
            .then(hasTransmissionStarted => {
                this.setState(prevState => ({
                    ...prevState,
                    hasTransmissionStarted
                }))
            })
            .catch(err => {
            })
    }

    render() {
        const {username} = this.props
        const {
            entitlements,
            isLoading,
            dialogText,
            hasTransmissionStarted
        } = this.state
        return (
            <div style={{
                paddingTop: 20,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>
                {isLoading === true ? <CircularProgress/> :
                    <React.Fragment>
                        {username && username.length > 0 && <div style={{marginBottom: 16}}>You are logged in as {username}</div>}
                        {hasTransmissionStarted && hasTransmissionStarted === true &&
                            <Paper style={{ height: 40, width: 300, marginBottom: 16}}>
                                <div style={{marginTop: 8, color: "green"}}>Transmission Started</div>
                            </Paper>
                        }
                        {(entitlements == null || entitlements.length === 0) ?
                            "No entitlements" :
                            <Grid container justify="center" spacing={3} alignItems="center">
                                {(entitlements && entitlements.length > 0) &&
                                (entitlements.map((value) => (
                                    <Grid key={value.entitlementName} item>
                                        {value.type === "MULT" &&
                                            <EntitlementCard
                                                style={{cursor: 'pointer'}}
                                                title={value.entitlementText}
                                                entitlementName={value.entitlementName}
                                                videoUrl={value.entitlementURL}
                                                imageUrl={value.entitlementImageURL}
                                                posterUrl={value.posterUrl}
                                                handleClick={this.handleClick}/>
                                        }
                                        {value.type === "ZOOM" &&
                                            <ZoomCard
                                                style={{cursor: 'pointer'}}
                                                title={value.entitlementText}
                                                entitlementName={value.entitlementName}
                                                videoUrl={value.entitlementURL}
                                                imageUrl={value.entitlementImageURL}
                                                handleClick={this.handleZoomClick}/>
                                        }
                                    </Grid>
                                )))}
                            </Grid>}
                    </React.Fragment>
                }
                {dialogText &&
                <AlertDialog
                    open={true}
                    title={''}
                    description={dialogText}
                    handleOkClicked={this.handleOkClicked}/>}
            </div>)

    }

    handleOkClicked() {
        this.setState({
            dialogText: null
        })
    }

    handleClick(url, name, poster) {
        this.setState({
            isLoading: true
        })
        checkEntitlementStatus(name, this.props.dispatch)
            .then(response => {
                this.setState({
                    isLoading: false
                })
                const status = response['enabled']
                if (status === true) {
                    this.props.history.push({
                        pathname: '/playback',
                        playbackUrl: url,
                        poster,
                        entitlementName: name
                    })
                }
                else if(status === false) { //Not enabled
                    var message = response['errorText']
                    if(message == null) {
                        message = "Access denied"
                    }
                    this.setState({
                        dialogText: message
                    })
                }
            })
            .catch(err => {
                this.setState({
                    dialogText: "Some error occurred"
                })
            })
        const entitlement = {
            playbackUrl: url,
            entitlementName: name
        }
        this.props.dispatch(addEntitlementChosen(entitlement))
    }

    handleZoomClick(url, name) {
        this.setState({
            isLoading: true
        })
        checkEntitlementStatus(name, this.props.dispatch)
            .then(response => {
                this.setState({
                    isLoading: false
                })
                const status = response['enabled']
                if (status === true) {
                        //recordUserAttendance(generateEventId(), name, this.props.dispatch).catch(err => {})
                        window.open(url)
                        return
                }
                else if(status === false) { //Not enabled
                    var message = response['errorText']
                    if(message == null) {
                        message = "Access denied"
                    }
                    this.setState({
                        dialogText: message
                    })
                }
            })
            .catch(err => {
                this.setState({
                    dialogText: "Some error occurred"
                })
            })
    }

}

const mapStateToProps = ({ session }) => ({
    username: session.userId,
})

export default connect(mapStateToProps)(Entitlements);
