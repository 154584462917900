import React from 'react'
import { connect } from "react-redux"
import { handleLogout } from '../utils/SessionUtils'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

class NavBar extends React.Component {

  constructor(props) {
    super(props)
    this.handleLogoutClicked = this.handleLogoutClicked.bind(this)
    this.handleHomeClicked = this.handleHomeClicked.bind(this)
  }

  render() {
    const { loggedIn } = this.props
    return (
      <React.Fragment style={{ flexGrow: 1 }}>
        <AppBar position="sticky" className="bg-dark">
          <Toolbar>
            <a className="navbar-brand" href="/">
              <img src="dayalbagh_logo_transparent_bg.gif" alt="Dayalbagh Logo" max-width="100" height="75" className="rounded" />
            </a>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              eSatsang Live Transmission
            </Typography>
            {/* <Button color="inherit">Home</Button> */}
            {loggedIn &&
              <div>
                <Button color="inherit" onClick={this.handleHomeClicked}>Home</Button>
                <Button color="inherit" onClick={this.handleLogoutClicked}>Logout</Button>
              </div>
            }
          </Toolbar>
        </AppBar>
      </React.Fragment>
    )
  }

  handleLogoutClicked() {
    handleLogout(this.props.dispatch)
  }

  handleHomeClicked() {
    window.location.href = '/'
  }
}

const mapStateToProps = ({ session }) => ({
  loggedIn: session.sessionId != null,
})

export default connect(mapStateToProps)(NavBar);
