import { fetchSessionId, getSavedDeviceUuid, getSavedUsername, saveDeviceUuid, saveUsername, saveSessionId } from '../utils/Prefs'
import * as SessionActions from '../actions/SessionActions'
import { handleLogout } from '../utils/SessionUtils'

//const BASE_URL = "wapi/"
const BASE_URL = "https://api.esatsang.live/wapi/"

export const fetchEntitlements = (dispatch) => {
    const sessionId = fetchSessionId()
    const uuid = getSavedDeviceUuid()
    const uid = getSavedUsername()
    let url = BASE_URL + 'v2/getUserEntitlements';
    let request = new Request(url, {
        method: 'GET',
        headers: {
            SESSION_TOKEN: sessionId,
            UUID: uuid,
            UID: uid
        }
    })
    return fetch(request)
        .then(response => validateSession(response, dispatch))
        .then(handleErrors)
        .then(response => response.json())
}

export const fetchMasterPlayList = (playbackUrl) => {
    let request = new Request(playbackUrl, {
        method: 'GET',
    })
    return fetch(request)
        .then(response => {
            return response.ok
        })
}

export const loginUser = (username, password, uuid, dispatch) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "UUID": uuid,
        },
        body: JSON.stringify({ username, password, "mode": 'web' })
    };
    let url = BASE_URL + 'loginUser';
    return fetch(url, requestOptions)
        .then(response => validateSession(response, dispatch))
        .then(response => handleErrors(response))
        .then(response => response.json())
        .then(data => {
            saveDeviceUuid(uuid)
            saveUsername(username)
            saveSessionId(data.sessionToken)
            dispatch(SessionActions.getLoggedInAction({ sessionId: data.sessionToken, userId: username, uuid }))
            return true
        })
}

export const checkIfTransmissionStarted = (dispatch) => {
    const sessionId = fetchSessionId()
    const uuid = getSavedDeviceUuid()
    const uid = getSavedUsername()
    let url = BASE_URL + 'getMetaValue?metaName=ACTIVE_SESSION';
    let request = new Request(url, {
        method: 'GET',
        headers: {
            SESSION_TOKEN: sessionId,
            UUID: uuid,
            UID: uid
        }
    })
    return fetch(request)
        .then(response => validateSession(response, dispatch))
        .then(handleErrors)
        .then(response => response.json())
}

export const recordUserAttendance = (eventID, entitlementName, dispatch) => {
    const sessionId = fetchSessionId()
    const uuid = getSavedDeviceUuid()
    const uid = getSavedUsername()
    let url = BASE_URL + 'recordUserAttendance';
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            SESSION_TOKEN: sessionId,
            UUID: uuid,
            UID: uid
        },
        body: JSON.stringify({ eventID, entitlementName })
    }
    return fetch(url, requestOptions)
        .then(response => validateSession(response, dispatch))
        .then(response => handleErrors(response))
        .then(response => response.json())
}

export const recordHeartbeat = (eventID, entitlementName, dispatch) => {
    const sessionId = fetchSessionId()
    const uuid = getSavedDeviceUuid()
    const uid = getSavedUsername()
    let url = BASE_URL + 'heartbeat';
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            SESSION_TOKEN: sessionId,
            UUID: uuid,
            UID: uid
        },
        body: JSON.stringify({ eventID, entitlementName })
    }
    return fetch(url, requestOptions)
        .then(response => validateSession(response, dispatch))
        .then(response => handleErrors(response))
        .then(response => response.json())
}

export const checkEntitlementStatus = (name,dispatch) => {
    const sessionId = fetchSessionId()
    const uuid = getSavedDeviceUuid()
    const uid = getSavedUsername()
    let url = BASE_URL + 'entitlement?entitlement_name='+name;
    let request = new Request(url, {
        method: 'GET',
        headers: {
            SESSION_TOKEN: sessionId,
            UUID: uuid,
            UID: uid
        }
    })
    return fetch(request)
        .then(response => validateSession(response, dispatch))
        .then(handleErrors)
        .then(response => response.json())
}

function validateSession(response, dispatch) {
    if (!response.ok) {
        if (401 === response.status) {
            handleLogout(dispatch)
        }
    }
    return response
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
