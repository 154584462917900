import React from 'react'
import { connect } from 'react-redux'
import {fetchMasterPlayList, recordHeartbeat, recordUserAttendance} from '../networking/Api'
import { getEntitlementName, getPlaybackUrl, saveEntitlementName, savePlaybackUrl } from '../utils/Prefs'
import CircularProgress from '@material-ui/core/CircularProgress'
import VideoPlayer from '../components/VideoPlayer'
import Paper from '@material-ui/core/Paper'
import { generateEventId } from '../utils/Utils'
import { ReactComponent as InActiveLogo } from '../cloud_off-black-48dp.svg'

class PlaybackPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            streamActive: false,
            width: 0,
            height: 0
        }
        this.heartbeatCall = this.heartbeatCall.bind(this)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
        this.metadataCallback = this.metadataCallback.bind(this)
    }

    componentDidMount() {
        const { playbackUrl } = this.props
        this.setState(prevState => ({
            ...prevState,
            isLoading: true
        }))
        if (playbackUrl != null) {
            fetchMasterPlayList(playbackUrl).then(resp => {
                this.setState(prevState => ({
                    ...prevState,
                    isLoading: false,
                    streamActive: resp
                }))
            }).catch(err => {
            })
        }
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
        if(this.state.intervalId) {
            clearInterval(this.state.intervalId)
        }
    }

    updateWindowDimensions() {
        //this.setState({ width: window.innerWidth, height: window.innerHeight })
    }

    render() {
        const { playbackUrl, poster } = this.props.location
        const { isLoading, streamActive } = this.state
        return (
            <div  >
                <div>
                    <header >
                        <h1 className="display-4 "><u>Ra</u> <u>Dha</u> <u>Sva</u> <u>Aa</u> <u>Mi</u></h1>
                        <p className="lead">Welcome to eSatsang Service.
                        <span className="text-primary"> </span>
                        </p>
                    </header>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                    {isLoading === true ?
                        <CircularProgress /> : (
                            streamActive === true ? <VideoPlayer {...{
                                metadataCallback: this.metadataCallback,
                                autoPlay: true,
                                controls: true,
                                poster,
                                playbackUrl
                            }} /> : <InactiveStream />
                        )
                    }
                </div>
            </div>
        )
    }

    metadataCallback() {
        recordUserAttendance(generateEventId(), this.props.entitlementName, this.props.dispatch).catch(err => { })
        this.heartbeatCall()
    }

    heartbeatCall() {
        recordHeartbeat(generateEventId(), this.props.entitlementName, this.props.dispatch)
            .then(data => {
                return data.code !== 1000;
            })
            .then(status => {
                if(status === false) {
                    window.location.href = '/'
                }
            })
            .catch(err => {
            })
        this.state.intervalId = setInterval(() => {
            recordHeartbeat(generateEventId(), this.props.entitlementName, this.props.dispatch)
                .then(data => {
                    return data.code !== 1000;
                })
                .then(status => {
                    if(status === false) {
                        window.location.href = '/'
                    }
                })
                .catch(err => {
                })
        }, 300000)
    }
}


class InactiveStream extends React.Component {
    render() {
        return (<div>
            <Paper style={{ height: 160, width: 300, paddingTop: 32, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
                <InActiveLogo />
                <div>Stream is not live right now</div>
            </Paper>
        </div>)
    }
}

const mapStateToProps = (store, ownProps) => {
    const { entitlements } = store
    var playbackUrl = null;
    var entitlementName = null;
    if (entitlements != null && entitlements.entitlement != null) {
        playbackUrl = entitlements.entitlement.playbackUrl
        entitlementName = entitlements.entitlement.entitlementName
        if (playbackUrl != null) {
            savePlaybackUrl(playbackUrl)
        }
        if (entitlementName != null) {
            saveEntitlementName(entitlementName)
        }
    }
    if (playbackUrl == null) {
        playbackUrl = ownProps.location.playbackUrl
        if (playbackUrl == null) {
            playbackUrl = getPlaybackUrl()
        }
        if (playbackUrl != null) {
            savePlaybackUrl(playbackUrl)
        }
    }
    if (entitlementName == null) {
        entitlementName = ownProps.location.name
        if (entitlementName == null) {
            entitlementName = getEntitlementName()
        }
        if (entitlementName != null) {
            saveEntitlementName(entitlementName)
        }
    }
    return {
        ...ownProps,
        playbackUrl: playbackUrl,
        entitlementName: entitlementName

    }
}

export default connect(mapStateToProps)(PlaybackPage)
