import React from 'react'
import ReactDOM from 'react-dom'
import thunk from 'redux-thunk'
import './index.css'
import App from './App'
import reducer from './reducers'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import * as serviceWorker from './serviceWorker'
import {fetchSessionId, getSavedDeviceUuid, getSavedUsername} from './utils/Prefs'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const initialState = {
  session: {
    sessionId: fetchSessionId(),
    uuid: getSavedDeviceUuid(),
    userId: getSavedUsername()
  },
  entitlements: {
    entitlement: null
  }
}

const store = createStore(
  reducer,
  initialState,
  composeEnhancers(
    applyMiddleware(thunk)
  )
)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
