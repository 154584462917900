import React from "react"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"

const mapStateToProps = (storeState, ownProps) => {
  const { sessionId } = storeState.session
  return {
    ...ownProps,
    loggedIn: sessionId != null
  }
};

const ProtectedRoute = ({ loggedIn, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loggedIn ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

export default connect(mapStateToProps)(ProtectedRoute);