export const LOGGED_IN = 'LOGGED_IN'
export const LOGGED_OUT = 'LOGGED_OUT'

export function getLoggedInAction(data) {
    const { sessionId, userId, uuid } = data
    return {
        type: LOGGED_IN,
        sessionId,
        userId,
        uuid
    }
}

export function getLoggedOutAction() {
    return {
        type: LOGGED_OUT,
    }
}