import React from "react"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"

const mapStateToProps = (storeState, ownProps) => {
  const { sessionId } = storeState.session
  return {
    ...ownProps,
    loggedIn: sessionId != null
  }
};

const LoginPageRoute = ({ loggedIn, component: Component, ...rest }) => {
  return (<Route
    {...rest}
    render={props =>
      loggedIn ? <Redirect to="/" /> : <Component {...props} />
    }
  />)
}

export default connect(mapStateToProps)(LoginPageRoute);