import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import { generateUUId } from '../utils/Utils'
import { loginUser } from '../networking/Api'
import { connect } from 'react-redux'
import AlertDialog from '../components/AlertDialog'

class DynPage extends React.Component {

    constructor(props) {
        super(props)
        this.onUserNameChanged = this.onUserNameChanged.bind(this)
        this.onPasswordChanged = this.onPasswordChanged.bind(this)
        this.onLoginClicked = this.onLoginClicked.bind(this)
        this.state = {
            username: '',
            password: '',
            loading: false,
            userError: false,
            passwordError: false,
            showErrorDialog: false
        }
        this.handleOkClicked = this.handleOkClicked.bind(this)
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);
        const secureToken = searchParams.get('secureToken')
        const sessionToken = searchParams.get('sessionToken')
        //Use above to fetch data from any APIs...
        //Have fallbacks for invalid token for any kind
    }

    componentWillReceiveProps() {
    }

    render() {
        const { userError, passwordError, username, password, loading, showErrorDialog } = this.state
        const isLoginDisabled = userError || passwordError || username == null || username.length === 0 || password == null || password.length === 0
        return (
            <div style={style}>
                <h1>Sign In</h1>
                <Paper variant="outlined" style={{ minHeight: 200, minWidth: 200, padding: 20 }}>
                    {loading === true ?
                        <div>
                            <CircularProgress />
                        </div>
                        : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <TextField error={userError} onChange={this.onUserNameChanged} id="outlined-basic" label="Username" variant="outlined" style={{ marginBottom: 16, width: 300 }} />
                            <TextField type="password" error={passwordError} onChange={this.onPasswordChanged} id="outlined-basic" label="Password" variant="outlined" style={{ marginBottom: 16, width: 300 }} />
                            <Button disabled={isLoginDisabled} onClick={this.onLoginClicked} variant="contained" color="primary" style={{ marginTop: 16 }}>Login</Button>
                        </div>
                    }
                </Paper>
                {showErrorDialog && <AlertDialog open={true} title={'Error'} description={'Incorrect username or password'}
                    handleOkClicked={this.handleOkClicked} />}
            </div>)
    }

    handleOkClicked() {
        this.setState((prevState) => ({
            ...prevState,
            showErrorDialog: false
        }))
    }

    onLoginClicked() {
        const uuid = generateUUId()
        const { username, password } = this.state
        const { dispatch } = this.props
        this.setState(prevState => ({
            ...prevState,
            loading: true
        }))
        loginUser(username, password, uuid, dispatch)
            .then(data => {
                this.setState((prevState) => ({
                    ...prevState,
                    loading: false
                }))
            })
            .catch(err => {
                this.setState((prevState) => ({
                    ...prevState,
                    loading: false,
                    showErrorDialog: true,
                    username: null,
                    password: null
                }))

            })
    }

    onUserNameChanged(event) {
        const newValue = event.target.value
        this.setState(prevState => ({
            ...prevState,
            username: newValue,
            userError: (newValue == null || newValue.length === 0) ? true : false
        }))
    }

    onPasswordChanged(event) {
        const newValue = event.target.value
        this.setState(prevState => ({
            ...prevState,
            password: newValue,
            passwordError: (newValue == null || newValue.length === 0) ? true : false
        }))
    }

}

const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
};

export default connect()(DynPage)