import React from 'react'
import Paper from '@material-ui/core/Paper'

export default class EntitlementCard extends React.Component {

    render() {
        const { title, imageUrl, handleClick, videoUrl, entitlementName, posterUrl } = this.props

        return (<Paper style={{ height: 160, width: 300, cursor: 'pointer' }} onClick={() => handleClick(videoUrl,entitlementName, posterUrl)} >
            <img src={imageUrl} style={{ height: 100, width: 300, objectFit: "cover" }} />
            <div>{title}</div>
            <div>Click to play</div>
        </Paper>)
    }

}
