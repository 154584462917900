import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class AlertDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            title: '',
            description: ''
        }
    }

    render() {
        const { open, title, description, handleOkClicked } = this.props
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {description}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/*                         <Button onClick={this.handleClose} color="primary">
                            Disagree
                  </Button> */}
                        <Button onClick={handleOkClicked} color="primary" autoFocus>
                            Ok
                  </Button>
                    </DialogActions>
                </Dialog>
            </div >
        )
    }

/*    handleClickOpen() {
        this.setState((prevState) => ({
            ...prevState,
            open: true
        }))
    }

    handleClose() {
        this.setState((prevState) => ({
            ...prevState,
            open: false
        }))
    }*/

}