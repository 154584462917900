const SESSION_ID = "sessionId"
const UUID = "uuid"
const USERNAME = "username"
const PLAYBACK_URL = "playback_url"
const ENTITLEMENT_NAME = "entitlement_name"

export const fetchSessionId = () => {
    return localStorage.getItem(SESSION_ID)
}

export const saveSessionId = (sessionId) => {
    if (sessionId == null) {
        localStorage.removeItem(SESSION_ID)
    }
    else {
        localStorage.setItem(SESSION_ID, sessionId)
    }
}

export const saveDeviceUuid = (uuid) => {
    if (uuid == null) {
        localStorage.removeItem(UUID)
    }
    else {
        localStorage.setItem(UUID, uuid)
    }
}

export const getSavedDeviceUuid = () => {
    return localStorage.getItem(UUID)
}

export const saveUsername = (username) => {
    if (username == null) {
        localStorage.removeItem(USERNAME)
    }
    else {
        localStorage.setItem(USERNAME, username)
    }
}

export const getSavedUsername = () => {
    return localStorage.getItem(USERNAME)
}

export const savePlaybackUrl = (playbackUrl) => {
    if (playbackUrl == null) {
        localStorage.removeItem(PLAYBACK_URL)
    }
    else {
        localStorage.setItem(PLAYBACK_URL, playbackUrl)
    }
}

export const getPlaybackUrl = () => {
    return localStorage.getItem(PLAYBACK_URL)
}

export const saveEntitlementName = (entitlementName) => {
    if (entitlementName == null) {
        localStorage.removeItem(ENTITLEMENT_NAME)
    }
    else {
        localStorage.setItem(ENTITLEMENT_NAME, entitlementName)
    }
}

export const getEntitlementName = () => {
    return localStorage.getItem(ENTITLEMENT_NAME)
}